import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a284c03424c9e80fd3f8e702d486bbf9/1fa90/ts-title.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.80694980694981%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAB2HAAAdhwGP5fFlAAACbUlEQVQozwFiAp39AF6Xn2mMg3aRiIyll3+Ujo6YmZ+4uJm1tKOxtNvn6Pf39dff36u3ttDQz7O0s6aspXOOhm1/fKywsLy/vwBxpq59jYiEkoyKop1+lJByg4KWpKONmpeQoKTP3uHd5OLf7OrN4OO5w8S4urmDkpBtfnppeXnHzczW29wAgJybkp+dd4qMfZWUhaWmgZmWjpyWmqGgoq+wzeHi2eblu9PUv9fax9ja2NrZsL2/a3t8ZHFzkZycx87PAJmrrJ6wsZSqrIymp4Suso64vZO2tZe1s6rAvdLo5eXu8Nzn37XFwZSjn/X38ezu5MzPyZ+npnWBgbvBwABjh4WOrKy3ycqhxcyMxM2TzNKKy9Kc1NSlzMfN5+rm7unv8N2zvKdlcWfU2tr09/Xc3920vLuBhn6nrKYAJkE+QVpWcpqbhrjGfr3LlcfRkcnUm9DYq9bYzOXl8Pj16+7kZ3FiKTUwt7/A8vb02d3btba0rKyqoqiiABsuLxUoKClFREd2fYq9x7nY37LP05K1uanKztnn6PT5+s3Y0VVmXg0YGay0tOfq5q6xqtHV0+bp5bvBvAAXKCgzTEopOTUvOS9id3SPn5yBkYePop6su7fN3NrI2t7Z6u1kcnMPFhiqrq3KysbBwb71+PXg5uK5vrsAEyAgGignNURBQlJHM0IxPkpBUlxWTVdVZ3FqX29uVG93fpueX3FzTVNTjo6KwsLB1NXU5OXmyc/SoaioAB8tLCEwMA8ZHDNAO1tkUkVYTj5TTUtZVDlGQEBKQio2OSMxMx0pKCQvLkFIRXV7epufnpaal46Yl5Gbm+R4a+rNPT8lAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Triangle Strategy Title",
            "title": "Triangle Strategy Title",
            "src": "/static/a284c03424c9e80fd3f8e702d486bbf9/e3189/ts-title.png",
            "srcSet": ["/static/a284c03424c9e80fd3f8e702d486bbf9/a2ead/ts-title.png 259w", "/static/a284c03424c9e80fd3f8e702d486bbf9/6b9fd/ts-title.png 518w", "/static/a284c03424c9e80fd3f8e702d486bbf9/e3189/ts-title.png 1035w", "/static/a284c03424c9e80fd3f8e702d486bbf9/44d59/ts-title.png 1553w", "/static/a284c03424c9e80fd3f8e702d486bbf9/a6d66/ts-title.png 2070w", "/static/a284c03424c9e80fd3f8e702d486bbf9/1fa90/ts-title.png 2118w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Ever since I played Final Fantasy Tactics and Ogre Battle, I've loved tactical RPGs. There have been few decent attempts at recreating the magic of FFT and even fewer of Ogre Battle. Like many others, I was hoping Triangle Strategy would be the spiritual successor to Tactics. So how does it measure up?`}</p>
    <h3>{`The Good`}</h3>
    <h4>{`The Combat`}</h4>
    <p>{`Any tactical RPG worth it's salt is going to have a decent combat system, and Triangle Strategy does not disappoint here. There are 30 characters for you to recruit over the course of your play through. You won't see all of them the first time around, some characters require massively high conviction scores in order to join your team, and some characters are tied to specific decisions you make. This is a nice incentive to replay multiple times. Each character has a "job" that they are stuck with throughout the game and there is definitely some overlap in most of the archetypes with additional specialization. For example, you have Hughette and Archibald as archers, and Hughette has enormous range of movement since she also rides a giant hawk, whereas Archibald has increased weapon range, but tends to favor mostly flat maps since a lot of his abilities require direct line of sight, but he does have a few AoE attacks as well. This is great as it does encourage some experimentation, and even if a character seems bad, there's probably at least one map where they will shine. Even Lionel, who is an absolute joke in combat, has a use because he can get you extra money and items during the non-story training battles.`}</p>
    <p>{`In terms of map variety, there is plenty here. Examples include docks, to castles, to mines, to the deck of two ships which requires you to zipline between ships. Many of the maps have environmental hazards that can be turned against your enemies, like shallow water that can be electrified; damaging and potentially paralyzing your enemies. This is a good time to mention, as I found out the hard way, while most of the time your characters attacks cannot hurt you, shocking water can paralyze your characters or pull Anna out of stealth, leading to some brutal, and totally avoidable, deaths. Many of these maps are also quite difficult, even if you are appropriately leveled. One that comes to mind was about two thirds of the way through the game, I found myself in a pincer attack on a bridge and failed that mission about five times before a change of strategy and the levels I accumulated during the failed attempts pushed me over the top. None of the battles, though hard, feel unfair but be prepared to have several of your character die during battle. There is no permadeath though, and if you fail completely you get to keep all your XP and Kudos (a special currency used to purchase abilities and upgrade items), and any items you may have used are given back to you. Furthermore, with the exception of the first two battles, you can then return to your camp and engage in training battles before returning back to battle.`}</p>
    <h4>{`Upgrade System`}</h4>
    <p>{`I mentioned earlier that each character has a class that they are locked into, and this is true, but each class can be upgraded two times, powering them up and unlocking some new abilities. The upgrades require level requirement to have been met and an upgrade item, Medals of Bravery for tier two and Medals of Valor for tier three. The kicker here is that there are only a set amount of these that can be acquired in a play-through. So some of the toughest choices you're going to make in the game are what characters to fully level up. This is nice, because it encourages playing the game multiple times to fully max out everyone. In general though, many of these class upgrades feel like minor stat boosts and nothing more, and I do wish that maybe the third tier led to a further specialization or something else to make it feel more impactful.`}</p>
    <p>{`Each character also has a weapon that they are stuck with that can also be upgraded twice. Much like the overlapping "jobs" for each character, weapon types can be shared amongst characters, but they have a different collection of abilities. The upgrades to the weapons do feel more impactful than the class upgrades, because you can unlock new abilities, increase the damage, duration, or range of abilities you like, or reduce the cost. There's plenty of variety of upgrades, and while you still can't max everyone's weapon out in one play-through, the resources you need are not as rare and you can get about six or seven of your characters decked out by the time the credits roll.`}</p>
    <h4>{`Art`}</h4>
    <p>{`The whole game is presented in the engine as used to make Octopath Traveler and the one they've rumored to be doing the next version of the Dragon Quest remakes in. This is a generally beautiful engine if you have any appreciate for pixel graphics. In this case, I'd say that the character models aren't quite as clear as the feel in Octopath, but the environments are absolutely gorgeous. I look forward to more games done with this engine.`}</p>
    <h3>{`The "Just Fine"`}</h3>
    <h4>{`Music`}</h4>
    <p>{`There are zero standouts here that I can think of, on either side of the tracks. The title screen track is probably is the best of the bunch, but when compared to Fire Emblem: Three Houses or Final Fantasy Tactics, there's just no comparison.`}</p>
    <h4>{`Story`}</h4>
    <p>{`The overall story here is not bad. It's not going to set the world on fire with it's writing or it's ideas, but it's a decent, low fantasy, political tale of the conflicts between three kingdoms. There are no monsters, dragons, gods, or extra planar beings. Just accept that magic is a thing that exists along with giant hawks and robotic horses that, in battle, act no differently than any other cavalry unit. There are definitely some good twists that occur towards the end of the game and some hard decisions you're going to have make.`}</p>
    <p>{`Sadly while the are several branching paths in the story, they do all join up again until the very end, so the illusion of "your choices matter" is meaningless until the final choice. The only exception here is character recruitment. Several early choices definitely affect some of the characters that will join you.`}</p>
    <h3>{`The Not Great`}</h3>
    <h4>{`The Characters`}</h4>
    <p>{`I really can't think of any single standout character, even amongst your core group of eight. The little bits of backstory you're drip fed through character stories or required cut scenes don't really add all that much. Maybe chalk it up to having such a large cast or the branching pathways, but I see no need to watch any optional character story.`}</p>
    <h4>{`The Voice Acting`}</h4>
    <p>{`This is an all to common problem with RPGs in general it seems. Not everyone can be an Ashley Burch, Troy Baker, or Nolan North, but my goodness, are there no middling level English voice actors who can speak as though they aren't made of wood? Every line delivered by absolutely everybody is completely devoid of emotion. It's like high quality robotic reading the whole time. Just read everything and move on.`}</p>
    <h4>{`The Pacing`}</h4>
    <p>{`Here's where the game really grinds to a halt. Let's ignore the fact that there are roughly forty minutes of dialogue to get through between the first and second battles, let's talk about what causes that forty minutes. Instead of playing every relevant scene either before or after each battle, what happens is you are forced out to a mostly empty world map, to click on bubbles in order to start whatever scene. I'd say about three quarters of the time these bubbles are required to advance the story too, so why not just play them all in sequence? The only reason I can think of is so that you know the location the event is happening. But, seriously, there are only ten or so points to jump between the full thirty-five hours or so, and they show the location on screen when the scene starts, so why? If they physically made your character travel there, like in Final Fantasy Tactics, it would have made more sense, but you don't get to do that here. Furthermore those character stories I've mentioned a few times, those show up in a different menu instead of happening organically. Every single new character you recruit goes through that exact same menu too, there's no `}<em parentName="p">{`discovering`}</em>{` them. They show up when you've met the requirements, you get a two minute cut scene that invariably ends with them meeting you in your tent and you will absolutely let them join. There's no way to reject them.`}</p>
    <h3>{`Final Thoughts`}</h3>
    <p>{`If you're a fan of tactical RPGs this one is definitely worth playing, but just be aware of what you're getting into. If you want story, you can never go wrong with the timeless Final Fantasy Tactics, if you're looking for good characters stick with Fire Emblem: Three Houses. There are four endings to found here. Three based on your final choice, and a fourth "happy" one if a certain path is taken for the whole game and a specific battle goes a certain way, certainly adds a reason for multiple play-throughs. I just wish the way the story was presented was cleaner and that I didn't have to play on mute. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      